import React from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';
import { Money, BarChart, Security } from '@mui/icons-material';

const features = [
  {
    title: 'Manage All Your Bookings',
    description: 'Access a wide range of tools to manage your bookings',
    icon: <Money fontSize="large" />
  },
  {
    title: 'Real-time Monitoring',
    description: 'Stay updated with all your revenues at a single place',
    icon: <BarChart fontSize="large" />
  }
];

const Features = () => {
  return (
    <Container sx={{ py: 8 }}>
      <Typography variant="h4" component="h2" gutterBottom align="center">
        Features
      </Typography>
      <Grid container spacing={4}>
        {features.map((feature, index) => (
          <Grid item key={index} xs={12} md={6}>
            <Box sx={{ textAlign: 'center' }}>
              {feature.icon}
              <Typography variant="h6" component="h3" gutterBottom>
                {feature.title}
              </Typography>
              <Typography variant="body1">
                {feature.description}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Features;
