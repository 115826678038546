import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import { IconButton } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu'

export default function TemporaryDrawer({ open, toggleDrawer, toggleView }) {



  const DrawerList = (
    <Box sx={{ width: 250}} role="presentation" >
      <List>
        {['Inbox', 'Appointments', 'Settings'].map((text, index) => (
          <ListItem key={text} disablePadding onClick={(e)=>toggleView(text)}>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div style={{marginTop: '80px'}}>
     <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
        style={{ position: 'fixed', top: 0, left: 0, zIndex: 130 }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer open={open} onClose={toggleDrawer(false)} variant='persistent'
        sx={{
            '& .MuiDrawer-paper': {
              top: '64px', // Adjust this value as needed
              width: '250px', // Set the width of the drawer
            },
          }}
      >
        {DrawerList}
      </Drawer>
    </div>
  );
}
