const _baseApiUrl = 'https://api.zoca.ai'
// const _baseApiUrl = 'http://localhost:8000'


export const loginApi = async (username, password) =>{
    const response = await fetch(`${_baseApiUrl}/login`, {
        method: "POST",
        headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
        username: username,
        password: password
        })
    });

    const data = await response.json();
    return data;
}


export const loginGoogleAuthApi = async (google_token) =>{
    const response = await fetch(`${_baseApiUrl}/verify-token`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            token: google_token,
            redirect_uri: window.location.origin
        })
    });
    const data = await response.json();
    return data;
}


export const signupApi = async (name, username, password) =>{
    const response = await fetch(`${_baseApiUrl}/signup`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            name: name,
            username: username,
            password: password
        })
    });

    const data = await response.json();
    return data;
}


export const getMailData = async (access_token) =>{
    const response = await fetch(`${_baseApiUrl}/getMailData?access_token=${access_token}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });

    const data = await response.json();
    return data;
}

export const getAppointmentData = async (access_token) =>{
    const response = await fetch(`${_baseApiUrl}/get_appointment_details?access_token=${access_token}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });

    const data = await response.json();
    return data;
}


export const getSettingsData = async (access_token) =>{
    const response = await fetch(`${_baseApiUrl}/get_settings?access_token=${access_token}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });

    const data = await response.json();
    return data;
}


export const updateSettings = async (access_token, settings) =>{
    const response = await fetch(`${_baseApiUrl}/update_settings`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            'access_token': access_token,
            'settings': settings
        })
    });
    const data = await response.json();
    return data;
}