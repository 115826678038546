import React from 'react';
import { Button } from '@mui/material';
import { useParams } from 'react-router-dom';

const AppLogin = () => {
    
    const { entityId } = useParams();
    const handleLogin = () => {
     // Fetching entityId from URL parameters
        window.location.href = `zoca://fetching-details?entityId=${entityId}`;
    };

    return (
        <div className="app-login" style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh'
        }}>
            <Button variant="contained" color="primary" onClick={handleLogin}>
                Log into Zoca
            </Button>
        </div>
    );
};

export default AppLogin;
