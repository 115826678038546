import React from 'react';
// import Header from './Header';
import LandingHeader from './LandingHeader';
import LadingFooter from './LandingFooter';
import Hero from './Hero';
import Features from './Features';
import CTA from './CTA';

const LandingPage = () => {
  return (
    <div>
      <LandingHeader />
      <Hero />
      <Features />
      <CTA />
      <LadingFooter />
    </div>
  );
};

export default LandingPage;
