import React from 'react';
import zocaLogo from '../assets/zoca_logo.png';
import { Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const LandingFooter = () => {
  return (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        padding: '20px', 
        backgroundColor: 'white', 
        color: 'black', 
        borderTop: '1px solid rgba(0, 0, 0, 0.12)', 
        position: 'relative', 
        bottom: 0, 
        width: '100%'
      }}
    >
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          gap: '20px' 
        }}
      >
        <Button color="inherit" component={Link} to="/privacy">Privacy Policy</Button>
        <Button color="inherit" component={Link} to="/terms-of-use">Terms of Use</Button>
        <Button color="inherit" component={Link} to="/limited-use-disclosure">Limited Use Disclosure</Button>
      </Box>
      <Typography 
        variant="body2" 
        sx={{ 
          marginTop: '10px', 
          fontSize: '14px' 
        }}
      >
        &copy; {new Date().getFullYear()} ZOCA. All rights reserved.
      </Typography>
    </Box>
  );
};

export default LandingFooter;
