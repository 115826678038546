import React, { createContext, useState, useEffect } from 'react';
import { loginApi , signupApi, loginGoogleAuthApi} from '../userapis/authapis';


const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user) {
      setUser(JSON.parse(user));
    }
    setLoading(false);
  }, []);

  const login = async (username, password) => {
    const data = await loginApi(username, password);
    console.log(data);
    if (data.access_token) {
      localStorage.setItem('user', JSON.stringify(data));
      setUser(user);
    }
  };

  const loginGoogleAuth = async (auth_cred) =>{
    const data = await loginGoogleAuthApi(auth_cred);
    console.log(data);
    if(data.access_token){
      localStorage.setItem('user', JSON.stringify(data));
      setUser(data)
    }
  }

  const logout = () => {
    localStorage.removeItem('user');
    setUser(null);
  };

  const signup = async (name, username, password ) =>{
    const data = await signupApi(name, username, password);
    console.log(data);
    if (data.access_token) {
      localStorage.setItem('token', data.access_token);
      setUser({ token: data.token });
      return true;
    }
    else{
      return false;
    }

  }

  
  return (
    <AuthContext.Provider value={{ user, loading, login, logout, signup, loginGoogleAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
