import zocaLogo from '../assets/zoca_logo.png';
import { Typography } from '@mui/material';

const DeleteAccount = () => {
    return (
    <div className="container-scroller">
        <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
            <Typography variant="h6" component="div" sx={{ display: 'flex', alignItems: 'center', color: "rgb(42, 214, 201)"}}>
                <img src={zocaLogo} alt="Logo" style={{ width: '50px', height: '50px', marginRight: '10px' }} />
                ZOCA
            </Typography>
            <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
                <button 
                    className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" 
                    type="button" 
                    data-toggle="offcanvas"
                >
                    <span className="typcn typcn-th-menu"></span>
                </button>
            </div>
        </nav>
        <div className="container-fluid page-body-wrapper full-page-wrapper">
            <div className="content-wrapper d-flex align-items-center auth px-0">
                <div className="container" style={{ marginTop: '150px' }}>
                    <div className="privacy-header">
                        <h1>Data Deletion Policy</h1>
                    </div>

                    <div className="privacy-container">
                        <h4>How to delete an account.</h4>
                        <div>
                            <p>
                                To delete your business profile with Zoca, you can email
                                us at admin@zoca.ai from your registered email id with
                                following format:
                            </p>
                            <p>
                                Subject: Request to delete my account <br/>
                                Mail body: I have a Zoca business profile in your database
                                with the business name ……………. and the email address that is
                                connected to the profile is ………………… I request you to delete my
                                account from your database
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div 
            className="modal fade" 
            id="exampleModal" 
            tabIndex="-1" 
            role="dialog" 
            aria-labelledby="exampleModalLabel" 
            aria-hidden="true"
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Login Error</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body d-flex flex-column align-items-center">
                        <img 
                            alt="Check both Checkboxes in Google Login" 
                            src="static/img/ezgif-1-3046df2dce.gif" 
                            style={{ height: '320px', width: '320px' }}
                        />
                        <h3 id="errorMsg"></h3>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default DeleteAccount;