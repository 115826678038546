import React, { useState, useEffect } from 'react';
import { CircularProgress, Container, Card, CardContent, Typography, Table, TableBody, TableCell, TableRow, Paper, TableContainer, TableHead } from '@mui/material';
import {   getMailData , getAppointmentData, getSettingsData} from '../userapis/authapis';
import useAuth from '../context/useAuth';
import Header from './Header';
import TemporaryDrawer from './Sidebar';
import Settings from './Settings';

const AppointmentList = ({ appointments }) => {
  console.log("Appointment data here: ", appointments)
  return (
    <Container>
        <Card>
          <CardContent>
            <Typography variant="h5" component="h2">
              Appointments
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Contact No</TableCell>
                    <TableCell>Appointment Time</TableCell>
                    <TableCell>Service</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {appointments.map((appointment) => (
                    <TableRow key={appointment.id}>
                      <TableCell>{appointment.name}</TableCell>
                      <TableCell>{appointment.contact_no}</TableCell>
                      <TableCell>{new Date(appointment.appointment_time).toLocaleString()}</TableCell>
                      <TableCell>{appointment.service_name}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Container>
  );
};

const Dashboard = () => {
    const { user} = useAuth();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [appointmentData, setAppointmentData] = useState(null);
    const [settingsData, setSettingsData] = useState(null);
    const [view, setView] = useState('Inbox')
    
    const fetchAppointment = async() =>{
      setLoading(true);
      try{
        const response = await getAppointmentData(user.access_token);
        
        setAppointmentData(response.appointments);
      } catch(error){
        setError(error.message);
      } finally{
        setLoading(false);
      }
    }


    const fetchSettings = async() =>{
      setLoading(true);
      try{
        const response = await getSettingsData(user.access_token);
        setSettingsData(response.settings);
      } catch(error){
          // setError(error.message);
          console.log("Error in fetching settings", error)
      } finally{
        setLoading(false);
      }
    }

    const toggleView = (view) =>{
      console.log("View changing", view);
      setView(view)
      setDrawerOpen(false);
      if(view === 'Appointments')fetchAppointment();
      if(view === 'Settings'){
        console.log("Settings view");
        fetchSettings();
      }
    }

    const toggleDrawer = (open) => (event) => {
      console.log("Toggle Drawer Clicked")
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      setDrawerOpen(!drawerOpen);
    };


    useEffect(() => {

      const fetchData = async () => {
        setLoading(true);
        try {
            const response = await getMailData(user.access_token);
            console.log("Response for mails", response)
            setData(response);
        } catch (error) {
            setError(error.message);
            console.error('Fetching data failed:', error);
        } finally {
            setLoading(false);
        }
      };
      console.log(user)
      if(user?.username){
        fetchData();
      }  
    }, [user]);

    const getDashboardData = ()=>{
      if (loading) {
        return (
          <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
          </Container>
        );
      }
    
      if (error) {
        return <Typography color="error">Error: {error}</Typography>;
      }



      return(<Container>
        <Card>
          <CardContent>
            <Typography variant="h5" component="h2">
              Mails
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Snippet</TableCell>
                    <TableCell>User ID</TableCell>
                    <TableCell>Email Body</TableCell>
                    <TableCell>Sender Email</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data && data.messages &&  data.messages.map((message, index) => (
                    <TableRow key={index}>
                      <TableCell>{message.snippet}</TableCell>
                      <TableCell>{message.user_id}</TableCell>
                      <TableCell>{message.email_body}</TableCell>
                      <TableCell>{message.sender_mail}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Container>)

    }


  return (
    <>
      <Header toggleDrawer={toggleDrawer(true)}/>
      <TemporaryDrawer open={drawerOpen} toggleDrawer={toggleDrawer}  toggleView={toggleView} />
      <div style={{marginTop: '64px'}}>
      {view === 'Inbox'&& getDashboardData()}
      {view === 'Appointments' && appointmentData &&  <AppointmentList appointments={appointmentData}/>
      }
      {view === 'Settings' && settingsData && <Settings settingsData={settingsData} access_token={user.access_token}/>}
      </div>
    </>
  );
};





export default Dashboard;
