import zocaLogo from '../assets/zoca_logo.png'

const PrivacyPage = () => {
    return (
        <div>
            <div className="container">
                <nav className="navbar">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <div className="row">
                                <div className="col">
                                    <img src={zocaLogo} alt="Logo" style={{ width: '50px', height: '50px' }} />

                                </div>
                                <div className="col">
                                    <h1 style={{fontSize: "40px", color: "rgb(42, 214, 201)"}}>ZOCA</h1>
                                </div>
                            </div>
                        </li>
                    </ul>
                </nav>
                <div className="bg-light py-2 align-items-center row"></div>
                <div className="row">
                    <h1 className="mt-4" style={{fontSize: "5rem"}}>Privacy Policy Notice</h1>
                </div>
                <div className="row" style={{marginTop: "20px"}}>
                    <div className="col-3">
                        <h4>Privacy Policy</h4>
                    </div>
                    <div className="col-9">
                        <p className="lead" style={{fontWeight: "bold"}}>Effective January 1, 2023</p>
                        <p>Zoca LLC (“Zoca”, “we”, or “us”) recognizes the importance of privacy. We
                            want you to feel confident using our services, and this privacy notice (“Privacy
                            Notice” or “Notice”) is to help you understand and describe how we collect, use,
                            and disclose your information.</p>
                        <p>Throughout this document, we will use a few terms to describe various
                            products and roles. All of the text, data, information, software, graphics,
                            photographs and more that we and our affiliates may make available to you, plus
                            our websites and any services, plug-ins, software, or applications (including
                            Zoca Click, Zoca for Chrome, Zoca for Firefox, and any Zoca applications) that
                            we may provide all together constitute the Zoca “Website.” Zoca “Users” are the
                            people who have created a Zoca account, and “Zoca Invitees” are people who have
                            scheduled a meeting with a Zoca User but may or may not have registered with
                            Zoca. A Zoca “Viewer” is a person who visits the Zoca Website but may or may not
                            be a Zoca User or Zoca Invitee.</p>
                        <p>Any terms defined in the Terms of Use, End User License Agreement, and Data
                            Processing Addendum and not otherwise defined in this Privacy Notice are hereby
                            adopted by reference in this Privacy Notice.</p>
                    </div>
                </div>
                <div className="row" style={{marginTop: "20px"}}>
                    <div className="col-3">
                        <h4>Information We Collect</h4>
                    </div>
                    <div className="col-9">
                        <p>We collect information about you directly from you and automatically through
                            your use of our Website. We may combine information automatically collected with
                            other information that we have collected about you including from publicly
                            available sources.</p>
                        <p>We allows users to sign in and sign up using their Google accounts for Single
                            Sign-On (SSO) functionality. When a user chooses to sign in using their Google
                            account, we collect and store their basic profile information provided by
                            Google.</p>
                        <p>In order to help you protect yourself and your information, we encourage you
                            to provide only that information that is necessary for using our Website. For
                            example, to schedule a meeting you may only need to provide us with a name,
                            email address, date, and time.</p>
                        <p>We utilize the Gmail API to read and analyze the content of User's emails.
                            It's important to note that we do not access any credentials, sensitive
                            information, or crucial emails. Our access is strictly limited to the scope
                            necessary for analyzing user behavior and providing strategic plans.</p>
                    </div>
                </div>
                <div className="row" style={{marginTop: "20px"}}>
                    <div className="col-3">
                        <h4>Information You Provide Voluntarily.</h4>
                    </div>
                    <div className="col-9">
                        <p>Zoca collects the following information, either directly from Zoca Users,
                            Invitees, or Viewers, or through third-parties regarding Users, Invitees, or
                            Viewers.</p>
                        <br/>
                        <p style={{fontWeight: "bold", marginTop: "20px", fontSize: "24px"}}>Appointment Information.</p>
                        <br/>
                        <p>A Zoca User, Invitee, or a third-party may voluntarily give us certain
                            information. This can include names, email addresses, and phone numbers; email
                            addresses of other people; the subject of the meeting; and any other information
                            provided to us or as required by the Zoca User upon scheduling, pursuant to the
                            Zoca Terms of Use. If you receive an invitation from a Zoca User but do not wish
                            to become a Zoca User, please contact the Zoca User who sent the invitation
                            through other means to set up your meeting.</p>
                        <br/>
                        <p style={{fontWeight: "bold", marginTop: "20px", fontSize: "24px"}}>Calendar Information.</p>
                        <br/>
                        <p>A Zoca User may connect their calendar with Zoca. Our calendar integration
                            checks the duration and free/busy status of the events in your calendar so that
                            we don’t book you when you’re busy. We even stores who you are meeting with,
                            their email address, the meeting title, or any other details about the
                            appointments in your connected calendar.</p>
                        <br/>
                        <p style={{fontWeight: "bold", marginTop: "20px", fontSize: "24px"}}>Usage of Contact List Information</p>
                        <br/>
                        <p>As a user of Zoca, you may opt to upload your Contact List to enhance the
                            service's capabilities. By doing so, Zoca accesses and stores relevant data such
                            as contact names, phone numbers, and email addresses to streamline appointment
                            setting and communication within your network. This feature operates under the
                            same privacy assurance as our calendar integration, treating your contacts'
                            information with the utmost confidentiality and using it solely for the intended
                            functionality of our application.</p>
                        <br/>
                        <p style={{fontWeight: "bold", marginTop: "20px", fontSize: "24px"}}>Usage of Images and Media</p>
                        <br/>
                        <p>When you voluntarily provide images &amp; media files to Zoca, these are used
                            to create a more personalized and engaging experience within the app. This may
                            include your professional portfolio, profile customization, and other features
                            where images are central to the service we provide. Like with calendar data,
                            images are used to enhance your online presence, support Local SEO optimization,
                            and promote your services through Zoca's platform, always in accordance with the
                            permissions granted by you and your clients.</p>
                    </div>
                </div>
                <div className="mb-4 row">
                    <div className="col-3">
                        <h4>Compliance with Google Guidelines</h4>
                    </div>
                    <div className="col-9">
                        <p>We strictly adhere to all Google guidelines for app verification and usage of
                            Google APIs. Our service operates within the permissible scope outlined by
                            Google's policies and terms of service.</p>
                    </div>
                </div>
                <div className="mb-4 row">
                    <div className="col-3">
                        <h4>Information Collected Automatically</h4>
                    </div>
                    <div className="col-9">
                        <p>Like many websites, we and our Service Providers use Cookies, web beacons,
                            and other technologies to receive and store certain types of information when
                            you interact with us through your computer or mobile device subject to your
                            opt-out preferences (see Your Rights and Choices section below). Using these
                            technologies helps us customize your experience with our Website, improve your
                            experience, and tailor marketing messages. Here are some of the types of
                            information we collect:</p>
                        <p>When you use Zoca, our servers automatically record information (“log data”),
                            including information that your browser sends whenever you visit our Website.
                            This log data may include the web address you came from or are going to, your
                            device model, operating system, browser type, unique device identifier, IP
                            address, mobile network carrier, and time zone or location. Whether we collect
                            some or all of this information often depends on what type of device you’re
                            using and its settings. For example, different types of information are
                            available depending on whether you’re using a Mac or PC, or an iPhone or an
                            Android phone. To learn more about what information your device makes available
                            to us, please check the policies of your device manufacturer or software
                            provider.</p>
                        <p>Depending on how you’re accessing our services and subject to your opt-out
                            preferences, we may use “Cookies” (a small text file sent by your computer each
                            time you visit our Website, unique to your Zoca account or your browser) or
                            similar technologies to record log data. When we use Cookies, we may use
                            ‘session’ Cookies (that last until you close your browser) or ‘persistent’
                            Cookies (that last until you or your browser deletes them). For example, we may
                            use Cookies to keep you logged into Zoca. Some of the Cookies we use are
                            associated with your Zoca account (including personal information about you,
                            such as the email address you gave us), and other Cookies are not.</p>
                        <p>Subject to your opt-out preferences (see ‘Your Rights and Choices’ below), we
                            use third-party Service Providers such as Google Analytics to provide certain
                            analytics and Viewer interactions services to Zoca in connection with our
                            operation of our Website, including the collection and tracking of certain data
                            and information regarding the characteristics and activities of visitors to
                            Zoca. You may opt-out of relevant cookies using opt-out features on their
                            respective websites.</p>
                    </div>
                </div>
                <div className="mb-4 row">
                    <div className="col-3">
                        <h4>How We Use Your Information</h4>
                    </div>
                    <div className="col-9">
                        <p>We may use information that we collect about you, including Personal Data, to:</p>
                        <p style={{fontWeight: "bold", marginTop: "20px", fontSize: "24px"}}>Provide the Zoca Service</p>
                        <p>We will use your information to provide our Website to you; including
                            facilitating scheduling; managing your account; responding to your inquiries;
                            and for other customer service and support purposes. We use the payment
                            information you provide to us in order to alert you of past, current, and
                            upcoming charges, to allow us to present the billing history to you on your
                            billing page in the Website, and to perform internal financial processes, such
                            as looking at the status of a credit card charge. In the event of a credit card
                            dispute, we also share account information with your bank to verify the
                            legitimacy of a charge.</p>
                        <p style={{fontWeight: "bold", marginTop: "20px", fontSize: "24px"}}>Understand and improve our products</p>
                        <p>We will use information about your interaction with our products, services, or content solely for the purpose of enhancing the functionality of our application and improving user experience. 
                            This includes conducting research and analysis to improve our services. 
                            We will not use your data for any other purposes, such as advertising or sharing with third parties, unless explicitly permitted by you.</p>
                        <p style={{fontWeight: "bold", marginTop: "20px", fontSize: "24px"}}>1.0 Communicate with you</p>
                        <p style={{fontWeight: "bold", marginTop: "20px", fontSize: "24px"}}>1.1 Service related communications</p>
                        <p>We may send you service and administrative emails to ensure the service is
                            working properly. We will also email you regarding your calendar appointments.
                            These messages are considered part of the service and you may not opt out of
                            these messages.</p>
                        <p style={{fontWeight: "bold", marginTop: "20px", fontSize: "24px"}}>Promotional</p>
                        <p>Subject to your opt-out preference, we may send you emails about new product
                            features or other news about Zoca or on topics we think would be relevant to
                            you. You may opt out of receiving these communications at any time. Visit the
                            ‘Your Rights and Choices’ section below. For Zoca Invitees, please be assured
                            that we do not use the email addresses that you enter to schedule a meeting with
                            a Zoca User to send any type of direct marketing.</p>
                        <p style={{fontWeight: "bold", marginTop: "20px", fontSize: "24px"}}>Responding to your requests</p>
                        <p>We will also use your information to respond to your questions or comments.</p>
                        <p style={{fontWeight: "bold", marginTop: "20px", fontSize: "24px"}}>Administrative</p>
                        <p>We may contact you to inform you about changes in your services, our service
                            offering and other important service-related notices, such as changes to the
                            Notice or about security or fraud notices.</p>
                        <p style={{fontWeight: "bold", marginTop: "20px", fontSize: "24px"}}>Protecting Rights and Interests</p>
                        <p>We will use your information to protect our rights and interests as well as
                            the rights and interests of our Users and any other person, as well as to
                            enforce this Notice or our Terms of Use.</p>
                        <p style={{fontWeight: "bold", marginTop: "20px", fontSize: "24px"}}>Legal Compliance</p>
                        <p>We may use your information to comply with applicable legal or regulatory
                            obligations, including complying with requests from law enforcement or other
                            governmental authorities, or in legal proceedings involving Zoca.</p>
                        <p style={{fontWeight: "bold", marginTop: "20px", fontSize: "24px"}}>Other</p>
                        <p>We also may use your information to manage our business or perform functions
                            as otherwise described to you at the time of collection subject to your consent.</p>
                    </div>
                </div>
                <div className="mb-4 row">
                    <div className="col-3">
                        <h4>With Whom We May Share Your Information</h4>
                    </div>
                    <div className="col-9">
                        <p>We do not sell your information to any third parties or disclose it in
                            exchange for money or other valuable consideration. We do not share your
                            Personal Data with others except as indicated within this Notice, or when we
                            inform you and give you an opportunity to opt-out of having your Personal Data
                            shared.</p>
                        <p>We will never use Invitee data to send direct marketing via emails, SMS,
                            whatsapp, physical mailings, or other similar communication channels to
                            advertise or promote the use of our product and services or those of a
                            third-party.</p>
                        <p style={{fontWeight: "bold", marginTop: "20px", fontSize: "24px"}}>Affiliates</p>
                        <p>We may disclose non-Google user data to our current or future affiliates or subsidiaries for research, marketing, or other purposes consistent with this Privacy Notice. 
                            However, we will not transfer Google user data to any third parties, 
                            including affiliates or subsidiaries, except when necessary to provide or improve our application's functionality, in compliance with Google’s data use policy.
                        </p>
                        <p>We may share your data if we believe that disclosure is reasonably necessary
                            to comply with a law, regulation, legal, or governmental request; to respond to
                            a subpoena, court order, warrant, or other legal process; to enforce applicable
                            Terms of Use or this Notice, including investigation of potential violations
                            thereof; to protect the safety, rights, or property of the public, any person,
                            or Zoca; to detect, prevent, or otherwise address, security or technical issues,
                            illegal, or suspected illegal activities (including fraud); or as evidence in
                            litigation in which we are involved, as part of a judicial or regulatory
                            proceeding.</p>
                        <p>If you are using Zoca as a paid member of an organization or using your
                            organization’s email domain (thereby representing yourself as a member of the
                            organization), we may share your email address and plan information with an
                            authorized agent of your company upon request in order for them to administer
                            the account for the company.</p>
                    </div>
                </div>
                <div className="mb-4 row">
                    <div className="col-3">
                        <h4>Your Rights and Choices</h4>
                    </div>
                    <div className="col-9">
                        <p style={{fontWeight: "bold", marginTop: "20px", fontSize: "24px"}}>Account</p>
                        <p>In order to keep your Personal Data accurate and complete, you can log in to
                            review and update your account information, including contact and billing
                            information, via your account settings page. You may also contact us to request
                            information about the personal data we have collected from you and to request
                            the correction, modification or deletion of such Personal Data. We will do our
                            best to honor your requests subject to any legal and contractual obligations. If
                            you would like to make a request, cancel your account or request we delete or no
                            longer use your account information to provide you our Website, contact us at
                            support@Zoca.com or the address set forth at the end of this Notice. Subject to
                            applicable law, we will retain and use your account information only as
                            necessary to comply with our legal obligations, resolve disputes, and enforce
                            our agreements.</p>
                        <p style={{fontWeight: "bold", marginTop: "20px", fontSize: "24px"}}>Email</p>
                        <p>As described above, if you do not wish to receive promotional emails from us,
                            you may opt out at any time by following the opt-out link contained in the email
                            itself. Please note that it may take up to ten (10) days to process your
                            request. Please also note that if you opt out of receiving marketing
                            communications from us, we may continue to send to you service-related emails
                            which are not available for opt-out. If you do not wish to receive any
                            service-related emails from us, you have the option to delete your account.</p>
                        <p style={{fontWeight: "bold", marginTop: "20px", fontSize: "24px"}}>Cookies</p>
                        <p>You may also refrain from providing or may withdraw your consent for Cookies
                            via your browser settings. Your browser’s help function should contain
                            instructions on how to set your computer to accept all Cookies, to notify you
                            when a Cookie is issued, or to not receive Cookies at any time. Please keep in
                            mind that Cookies are required to authenticate Zoca Users as well as perform
                            some actions within Zoca (such as to pay for an event as an Invitee via Zoca),
                            so in order to use the Website, some Cookies are often required. You may also
                            manage the use of targeting, performance, and functional cookies on this website
                            by clicking the Cookie Settings link located on the footer of this page.</p>
                        <p style={{fontWeight: "bold", marginTop: "20px", fontSize: "24px"}}>Additional Rights</p>
                        <p>Subject to local law, you may have additional rights under the laws of your
                            jurisdiction regarding your personal data, such as the right to complain to your
                            local data protection authority.</p>
                    </div>
                </div>
                <div className="mb-4 row">
                    <div className="col-3">
                        <h4>Security and Storage of Information</h4>
                    </div>
                    <div className="col-9">
                        <p>We have taken reasonable steps to help protect the personal information we
                            collect. More information on Zoca security and storage practices is available
                            here.</p>
                        <p>Zoca takes the security of your personal data very seriously. We work hard to
                            protect the personal data that you provide from loss, misuse, and unauthorized
                            access, or disclosure. Given the nature of communications and information
                            processing technology, there is no guarantee that personal data will be
                            absolutely safe from access, alteration, or destruction by a breach of any of
                            our physical, technical, and managerial safeguards. You should take steps to
                            protect against unauthorized access to your device and account by, among other
                            things, choosing a unique and complex password that nobody else knows or can
                            easily guess and keeping your log-in and password private. We are not
                            responsible for any lost, stolen, or compromised passwords or for any activity
                            on your account via unauthorized password activity.</p>
                        <p>We retain the personal data we collect for so long as is reasonably necessary
                            to fulfill the purposes for which the data was collected, to perform our
                            contractual and legal obligations, and for any applicable statute of limitations
                            periods for the purposes of bringing and defending claims.</p>
                    </div>
                </div>
                <div className="mb-4 row">
                    <div className="col-3">
                        <h4>Third-Party Links</h4>
                    </div>
                    <div className="col-9">
                        <p>Our Website may contain links to third-party websites and applications.
                            Subject to your opt-out preferences (see Your Rights and Choices), we may also
                            use third-party advertisers, ad networks, and other advertising, marketing, and
                            promotional companies, to serve advertisements on our Website. Any access to and
                            use of such linked websites and applications is not governed by this Notice but
                            instead is governed by the privacy policies of those third parties. We do not
                            endorse these parties, their content, or any products and services they offer,
                            and we do not endorse these parties, their content, or any products and services
                            they offer, and we are not responsible for the information practices of such
                            third-party websites or applications.</p>
                    </div>
                </div>
                <div className="mb-4 row">
                    <div className="col-3">
                        <h4>Users Outside the INDIA</h4>
                    </div>
                    <div className="col-9">
                        <p>Our application and database servers are located here in the India.</p>
                        <p>If you are an individual located in the European Economic Area, the United
                            Kingdom, Canada or another jurisdiction outside of the United States with laws
                            and regulations governing personal data collection, use, and disclosure that
                            differ from United States laws, please be aware that information we collect
                            (including through the use of methods such as Cookies and other web
                            technologies) will be processed and stored in the United States or in other
                            countries where we or our third-party Service Providers have operations. By
                            submitting your personal data to Zoca and using Zoca, you expressly consent to
                            have your personal data transferred to, processed, and stored in the India or
                            another jurisdiction which may not offer the same level of privacy protection as
                            those in the country where you reside or are a citizen.</p>
                        <p>In connection with the operation of its Website, Zoca may transfer your
                            personal data to various locations, which may include locations both inside and
                            outside of the European Economic Area. We process personal data submitted
                            relating to individuals in Europe via the Standard Contractual Clauses.</p>
                    </div>
                </div>
                <div className="mb-4 row">
                    <div className="col-3">
                        <h4>Do Not Track</h4>
                    </div>
                    <div className="col-9">
                        <p>We do not currently recognize or respond to browser-initiated Do Not Track
                            signals as there is no consistent industry standard for compliance.</p>
                    </div>
                </div>
                <div className="mb-4 row">
                    <div className="col-3">
                        <h4>Dispute Resolution</h4>
                    </div>
                    <div className="col-9">
                        <p>Details regarding Zoca’s dispute resolution process are available in the Zoca
                            Terms of Use.</p>
                    </div>
                </div>
                <div className="mb-4 row">
                    <div className="col-3">
                        <h4>Contacting Us</h4>
                    </div>
                    <div className="col-9">
                        <p>If you have any questions or comments about this Notice, please contact us at
                            contact@Zoca.com. For questions or comments outside of the Privacy Notice,
                            please contact us at contact@Zoca.com.</p>
                    </div>
                </div>
                <div className="mb-4 row">
                    <div className="col-3">
                        <h4>Sharing Personal Information</h4>
                    </div>
                    <div className="col-9">
                        <p>We may disclose your Personal Information to a third-party for a business
                            purpose. When we disclose Personal Information for a business purpose, we enter
                            a contract that describes the purpose and requires the recipient to both keep
                            that Personal Information confidential and not use it for any purpose except
                            performing your contract.
                        </p>
                        <p>We disclose your Personal Information for a business purpose to the following
                            categories of third parties:</p>
                        <ul>
                            <li>Service Providers.</li>
                            <li>Third parties to whom you or your agents authorize us to disclose your
                                Personal Information in connection with the services we provide to you.</li>
                            <li>Third parties to whom you or your agents authorize us to disclose your
                                Personal Information in connection with the services we provide to you.</li>
                        </ul>
                    </div>
                </div>
                <div className="mb-4 row">
                    <div className="col-3">
                        <h4>Deletion Request Rights</h4>
                    </div>
                    <div className="col-9">
                        <p>You have the right to request that we delete any of your Personal Information
                            that we have collected from you and retained, subject to certain exceptions. All
                            deletion requests will be managed in accordance with Zoca Deletion Procedures.
                            Deletion requests should be sent to contact@Zoca.com. Once we receive and
                            confirm your verifiable request, we will delete (and direct our Service
                            Providers to delete) your Personal Information from our records, unless an
                            exception applies.</p>
                    </div>
                </div>
                <div className="mb-4 row">
                    <div className="col-3">
                        <h4>Changes to Our Privacy Notice</h4>
                    </div>
                    <div className="col-9">
                        <p>We reserve the right to amend this privacy notice at our discretion and at
                            any time and will notify you by email or through a notice on our Website
                            homepage.</p>
                    </div>
                </div>
                <div className="mb-4 row">
                    <div className="col-3">
                        <h4>Contact Information</h4>
                    </div>
                    <div className="col-9">
                        <p>If you have any questions or comments about this notice, our Privacy Notice,
                            the ways in which we collect and use your Personal Information, your choices and
                            rights regarding such use, please do not hesitate to contact us at:
                            contact@Zoca.com.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPage;