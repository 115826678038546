import zocaLogo from '../assets/zoca_logo.png'

const LimitedUseDisclosure = () => {
    return (
        <div>
            <div className="container">
                <nav className="navbar">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <div className="row">
                                <div className="col">
                                    <img src={zocaLogo} alt="Logo" style={{ width: '50px', height: '50px' }} />

                                </div>
                                <div className="col">
                                    <h1 style={{ fontSize: "40px", color: "rgb(42, 214, 201)" }}>ZOCA</h1>
                                </div>
                            </div>
                        </li>
                    </ul>
                </nav>
                <div className="bg-light py-2 align-items-center row"></div>
                <div class="container">
                <div class="privacy-header">
                    <h1>Limited Use Disclosure</h1>
                </div>
                <div class="privacy-container">
                    <h3>ZocaAI's use and transfer to any other app of information received from Google APIs will adhere to <br/>
                        <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" >
                            Google API Services User Data Policy
                        </a>,
                        including the Limited Use requirements.</h3>
                </div>
                <br/><br/><br/>
                <div class="privacy-container">
                    <h2><u>Additional Links :</u></h2>
                </div>
                <div class="privacy-container">
                    <h3>
                        Privacy Policy - <a href="https://zoca.ai/privacy" >https://zoca.ai/privacy</a>
                    </h3>
                </div>

                <div class="privacy-container">
                    <h3>
                        Terms Of Use - <a href="https://zoca.ai/terms-of-use">https://zoca.ai/terms-of-use</a>
                    </h3>

                </div>
            
            </div>
            </div>
        </div>
    )
}

export default LimitedUseDisclosure;