import React from 'react';

const SupportPage = () => {
    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
    };

    return (
        <div
            className="page-wrapper"
            style={{
                display: 'flex',
                flexDirection: 'row',
                height: '100vh',
                flexWrap: 'wrap', // Allow wrapping for responsiveness
            }}
        >
            {/* Left Section */}
            <div
                className="information_section"
                style={{
                    flex: 1,
                    backgroundColor: '#f5f5f5',
                    padding: '40px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    textAlign: 'center', // Center text for mobile
                }}
            >
                <h1
                    style={{
                        fontSize: '2rem', // Reduced size for mobile
                        fontWeight: 'bold',
                        marginBottom: '1rem',
                    }}
                >
                    Support and Help
                </h1>
                <h2
                    style={{
                        fontSize: '1.5rem', // Reduced size for mobile
                        fontWeight: 'normal',
                        marginBottom: '1.5rem',
                        lineHeight: '1.5',
                    }}
                >
                    Committed to providing you the best experience.{' '}
                    <span style={{ fontWeight: 'bold' }}>We are here for you.</span>
                </h2>
                <p style={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                    email: admin@zoca.ai
                </p>
                <p style={{ fontSize: '0.9rem', color: '#666' }}>
                    We will get back to you within 7 days. Feel free to email us for immediate assistance.
                </p>
            </div>

            {/* Right Section (Form) */}
            <div
                className="form_section"
                style={{
                    flex: 1,
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                }}
            >
                <h2 style={{ fontSize: '1.8rem', fontWeight: 'bold', marginBottom: '20px', textAlign: 'center' }}>
                    Contact Us
                </h2>
                <form onSubmit={handleSubmit} style={{ maxWidth: '500px', width: '100%', margin: '0 auto' }}>
                    <div style={{ marginBottom: '20px' }}>
                        <label
                            htmlFor="name"
                            style={{
                                display: 'block',
                                marginBottom: '8px',
                                fontSize: '1rem',
                                fontWeight: 'bold',
                            }}
                        >
                            Your name
                        </label>
                        <input
                            type="text"
                            id="name"
                            placeholder="John Doe"
                            required
                            style={{
                                width: '100%',
                                padding: '12px',
                                border: '1px solid #ddd',
                                borderRadius: '4px',
                                fontSize: '1rem',
                            }}
                        />
                    </div>

                    <div style={{ marginBottom: '20px' }}>
                        <label
                            htmlFor="phone"
                            style={{
                                display: 'block',
                                marginBottom: '8px',
                                fontSize: '1rem',
                                fontWeight: 'bold',
                            }}
                        >
                            Phone number
                        </label>
                        <input
                            type="tel"
                            id="phone"
                            placeholder="Phone number"
                            required
                            style={{
                                width: '100%',
                                padding: '12px',
                                border: '1px solid #ddd',
                                borderRadius: '4px',
                                fontSize: '1rem',
                            }}
                        />
                    </div>

                    <div style={{ marginBottom: '20px' }}>
                        <label
                            htmlFor="email"
                            style={{
                                display: 'block',
                                marginBottom: '8px',
                                fontSize: '1rem',
                                fontWeight: 'bold',
                            }}
                        >
                            Email address
                        </label>
                        <input
                            type="email"
                            id="email"
                            placeholder="johnsmith@example.com"
                            required
                            style={{
                                width: '100%',
                                padding: '12px',
                                border: '1px solid #ddd',
                                borderRadius: '4px',
                                fontSize: '1rem',
                            }}
                        />
                    </div>

                    <button
                        type="submit"
                        style={{
                            backgroundColor: '#6C5CE7',
                            color: 'white',
                            padding: '12px 24px',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            width: '100%',
                        }}
                    >
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
};

export default SupportPage;
