import React , { useState, useEffect } from 'react';
import { Container, Card, CardContent, Typography, List, ListItem, ListItemText, Paper, Button, TextField, Box, Divider, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { updateSettings } from '../userapis/authapis';

const Settings = ({ settingsData, access_token }) => {

  const [emails, setEmails] = useState(settingsData?.appointment_emails);
  const [newEmail, setNewEmail] = useState('');

  const [settings, setSettings] = useState(settingsData);


    useEffect(() => {
        updateSettings(access_token, { ...settings, appointment_emails: emails })
        setSettings(prevSettings => ({ ...prevSettings, appointment_emails: emails }));
        
    }, [emails]);

    console.log("Settings data here: ", settingsData)
    if (!settingsData ) {
        return (
        <Container>
            <Typography variant="h5" component="h2">
            Loading settings...
            </Typography>
        </Container>
        );
    }







  const handleAddEmail = () => {
    if (newEmail && !emails.includes(newEmail)) {
      setEmails([...emails, newEmail]);
      setNewEmail('');
    }

  };

  const handleRemoveEmail = (emailToRemove) => {
    setEmails(emails.filter(email => email !== emailToRemove));
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Card elevation={3}>
        <CardContent>
          <Typography variant="h4" component="h1" gutterBottom>
            Settings
          </Typography>
          <Divider sx={{ mb: 3 }} />
          <Typography variant="h6" component="h2" gutterBottom>
            Appointment Emails
          </Typography>
          <Paper elevation={0} sx={{ bgcolor: 'background.default', p: 2 }}>
            <List>
              {emails && emails?.map((email, index) => (
                <ListItem
                  key={index}
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveEmail(email)}>
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText primary={email} />
                </ListItem>
              ))}
            </List>
            <Box sx={{ display: 'flex', mt: 2 }}>
              <TextField
                fullWidth
                size="small"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                placeholder="Add new email"
                sx={{ mr: 1 }}
              />
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleAddEmail}
                disabled={!newEmail}
              >
                Add
              </Button>
            </Box>
          </Paper>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Settings;
