import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';

const CTA = () => {
  return (
    <Box sx={{ bgcolor: '#1976d2', color: '#fff', py: 8 }}>
      <Container maxWidth="sm" sx={{ textAlign: 'center' }}>
        <Typography variant="h4" component="h2" gutterBottom>
          Ready to get started?
        </Typography>
        <Typography variant="h6" paragraph>
          Join us today and take control of your investments!
        </Typography>
        <Button variant="contained" color="secondary" size="large">
          Sign Up Now
        </Button>
      </Container>
    </Box>
  );
};

export default CTA;
