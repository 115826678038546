import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom'; 

const Hero = () => {
  const navigate = useNavigate()
  return (
    <Box sx={{ bgcolor: '#f5f5f5', py: 8 }}>
      <Container maxWidth="sm" sx={{ textAlign: 'center' }}>
        <Typography variant="h2" component="h1" gutterBottom>
          Zoca AI
        </Typography>
        <Typography variant="h5" paragraph>
          Monitor and manage all your booking from a single place. 
        </Typography>
        <Button variant="contained" color="primary" size="large"
          onClick={() => navigate('/login')}
        >
          Get Started
        </Button>
      </Container>
    </Box>
  );
};

export default Hero;
