import zocaLogo from '../assets/zoca_logo.png'
import React from 'react';
import useAuth from '../context/useAuth';
import { Box, Button, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'
import { AppBar, Toolbar, Typography } from '@mui/material';
function Header({toggleDrawer}) {
  const { logout} = useAuth();

  const handleLogout = (e) =>{
    logout();
  }

  return (
    // <AppBar style={{ position:"static", zIndex: 1301, color: 'white' }}>
    //   <Box sx={{
    //     display: 'flex',
    //     justifyContent: 'flex-start'
    //   }}>
    //     <img src={zocaLogo} alt="Logo" style={{ width: '50px', height: '50px' }} />
    //     <h1 style={{ marginLeft: '20px', fontSize: '40px', color: "rgb(42, 214, 201)", fontFamily: "Poppins Roboto Helvetica Neue Arial sans-serif"}}>Zoca</h1>
    //   </Box>
    //   <Button onClick={handleLogout}>Logout</Button>
    // </AppBar>
    <AppBar position="fixed" style={{ zIndex: 1301 , background: 'white'}}>
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton edge="start"  aria-label="menu" onClick={toggleDrawer}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ display: 'flex', alignItems: 'center', color: "rgb(42, 214, 201)"}}>
          <img src={zocaLogo} alt="Logo" style={{ width: '50px', height: '50px', marginRight: '10px' }} />
          ZOCA
        </Typography>
      </div>
      <Button  onClick={handleLogout}>Logout</Button>
    </Toolbar>
    
  </AppBar>
  );
}

export default Header;