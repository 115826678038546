import React from 'react';
import zocaLogo from '../assets/zoca_logo.png'
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const LandingHeader = () => {
  return (
    <AppBar position="static" sx={{marginBottom: '50px', height: '70px', color: 'black', backgroundColor: 'white'}} >
      <Toolbar variant='dense' position='fixed'> 
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center'
            }}>
                <img src={zocaLogo} alt="Logo" style={{ width: '50px', height: '50px' }} />
                <h1 style={{ marginLeft: '20px',fontWeight: '400',  fontSize: '40px', color: 'rgb(42, 214, 201)', fontFamily: "Poppins,Roboto,Helvetica Neue,Arial,sans-serif"}}>ZOCA</h1>
            </Box>
            <Box sx={{
                position: 'absolute',
                right: '0px'
            }}>
                <Button color="inherit" component={Link} to="/login">Login</Button>
                <Button color="inherit" component={Link} to="/signup">Sign Up</Button>
            </Box>
      </Toolbar>
    </AppBar>
  );
};

export default LandingHeader;
