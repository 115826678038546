import React, { useState } from 'react';
import { TextField, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useAuth from '../context/useAuth';
import LandingHeader from './LandingHeader';

const Signup = () => {
  const [name, setname] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const { signup } = useAuth(); // Assuming you have a signup function in your auth context
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(!name){
        alert('Please Enter Your Name')
        return ;
    }
    const signupSucess = await signup(name, email,password); // Adjust to match your signup API
    if(signupSucess)navigate('/');
  };

  return (
    <>
      <LandingHeader />
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          justifyContent: 'center',
          alignItems: 'center',
          height: '70vh',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: '300px',
          }}
        >
          <TextField
            label="Name"
            variant="outlined"
            value={name}
            onChange={(e) => setname(e.target.value)}
            fullWidth
            sx={{
              marginBottom: '10px',
            }}
          />
        <TextField
            label="Email"
            type="email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            sx={{
              marginBottom: '10px',
            }}
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            sx={{
              marginBottom: '10px',
            }}
          />

          <Button type="submit" variant="contained" color="primary" sx={{ width: '100px' }}>
            Sign Up
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Signup;
