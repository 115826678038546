import React, { useState, useEffect } from 'react';
import { TextField, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom'; 
import useAuth from '../context/useAuth';
import LandingHeader from './LandingHeader';
import { useGoogleLogin, GoogleLogin } from '@react-oauth/google';


const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [googleCredJWT, setGoogleCredJWT] = useState('');
  const [googleCred, setGoogleCred] = useState('');
  const { login, loginGoogleAuth , user} = useAuth();
  const navigate = useNavigate(); 

  const handleSubmit = async (e) => {
    e.preventDefault();
    await login(username, password);
    navigate('/');
  };

  useEffect(() =>{
    if(user){
      navigate('/')
    }
  }, [])



  const googleLogin = useGoogleLogin({
    
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse)
      setGoogleCredJWT(tokenResponse)
      await loginGoogleAuth(tokenResponse.code);
      navigate('/')
    },
    onError: error => console.log(error),
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/gmail.readonly profile openid', 
  });

  const googleLoginError = (error) => {
    console.log(error)
  }

  return (
    <>
    <LandingHeader></LandingHeader>
    
    <Box 
        component="form" 
        onSubmit={handleSubmit}
        sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        gap: 2, 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '70vh', 
      }}>
        {/* <Box sx={{
          display: 'flex', 
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column', 
          width: '300px'
        }}>
            <TextField
            label="Username"
            variant="outlined"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            fullWidth
            sx={{
                marginBottom: '10px'
            }}
            />
        <TextField
            label="Password"
            type="password"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            sx={{
                marginBottom: '10px'
            }}
        />
        <Button type="submit" variant="contained" color="primary" sx={{width: '50px'}}>
            Login
        </Button>
         
      </Box> */}
      <GoogleLogin 
        onSuccess={googleLogin}
        onError={googleLoginError}
      />
    </Box>

    </>
  );
};

export default Login;
