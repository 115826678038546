import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivateRoute from './routes/PrivateRoute';
import Login from './components/login';
import Dashboard from './components/Dashboard';
import PrivacyPage from './components/Privacy';
import { AuthProvider } from './context/AuthContext';
import LandingPage from './components/LandingPage';
import Signup from './components/Signup';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import TermsOfUse from './components/TermsOfUse';
import LimitedUseDisclosure from './components/LimitedUseDisclosure';
import DeleteAccount from './components/DeleteAccount';
import AppLogin from './components/AppLogin';
import SupportPage from './components/SupportPage';
function App() {
  return (
    <GoogleOAuthProvider clientId='523972839072-p1n1sm33u84q7it0v02mhso0spsgse8c.apps.googleusercontent.com'>
      <AuthProvider>
        <Router>
          
          <Routes>
            <Route path="/login" element={<Login/>} />
            <Route path='/signup' element={<Signup/>} />
            <Route path='/landing' element={<LandingPage/>} />
            <Route path='/privacy' element={<PrivacyPage/>} />
            <Route path='/terms-of-use' element={<TermsOfUse/>} />
            <Route path='/limited-use-disclosure' element={<LimitedUseDisclosure/>} />
            <Route path='/delete-account-policy' element={<DeleteAccount/>} />
            <Route path='/app-login/:entityId' element={<AppLogin/>} />
            <Route path="/" element={<PrivateRoute />}>
              <Route path='/' element={<Dashboard/>} />
              {/* <Route path="/chat" element={<Chat />} /> */}
            </Route>
            <Route path='/support' element={<SupportPage/>} />
          </Routes>
        </Router>
      </AuthProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
